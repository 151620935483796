import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";

import {
  useGetAgrochemicalsCategoriesQuery,
  useGetSeedsCategoriesQuery,
  useGetFoodStuffCategoriesQuery,
} from "../../slices/categoryApiSlice";
import { Link } from "react-router-dom";

const ProductCards = () => {
  const formatMoney = (num) =>
    "₦" + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const size = 6;
  const { data, isLoading, error } = useGetAgrochemicalsCategoriesQuery();
  const {
    data: seeds,
    isLoading: setLoading,
    error: setError,
  } = useGetSeedsCategoriesQuery();
  const { data: foodStuff } = useGetFoodStuffCategoriesQuery();
  const agrochemicalItems = data?.products?.slice(0, size)?.map((product) => (
    <SwiperSlide>
      <div
        className="product-image-card bg-white rounded-lg text-slate-700"
        key={product.slug}
      >
        <Link to={`/products/${product.slug}`} className="w-full my-2">
          <img
            src={product.photos[0].img}
            alt=""
            className="product-image-card"
          />
          <div className="text-center font-semibold text-sm mx-1 ">
            <h1 className="my-1">{product.title.substring(0, 25)}..</h1>
            <p className="font-bold p-1">{formatMoney(product.price)}</p>
          </div>
        </Link>
      </div>
    </SwiperSlide>
  ));

  const seedItems = seeds?.products?.slice(0, size)?.map((product) => (
    <SwiperSlide>
      <div className="bg-white rounded-lg text-slate-700" key={product.slug}>
        <Link to={`/products/${product.slug}`} className="w-full my-2">
          <img
            src={product.photos[0].img}
            alt=""
            className="product-image-card "
          />
          <div className="mx-1 text-center font-semibold text-sm">
            <h1 className="my-1">{product.title.substring(0, 25)}..</h1>
            <p className="font-bold p-1">{formatMoney(product.price)}</p>
          </div>
        </Link>
      </div>
    </SwiperSlide>
  ));

  const foodStuffItems = foodStuff?.products?.slice(0, size)?.map((product) => (
    <SwiperSlide>
      <div className="bg-white rounded-lg text-slate-700" key={product.slug}>
        <Link to={`/products/${product.slug}`} className="w-full my-2">
          <img
            src={product.photos[0].img}
            alt=""
            className="product-image-card"
          />
          <div className="mx-1 text-center font-semibold  text-sm">
            <h1 className="my-1">{product.title.substring(0, 25)}..</h1>
            <p className="font-bold p-1">{formatMoney(product.price)}</p>
          </div>
        </Link>
      </div>
    </SwiperSlide>
  ));

  return (
    <div className="my-6 text-slate-700">
      <section className="agrochemical-card my-4 lg:my-10">
        <div className="flex justify-between items-center my-2">
          <h2 className="text-lg font-bold ">Agrochemicals</h2>
          <Link
            to="/category/agrochemicals"
            className="hover:underline bg-green-700 text-white  rounded-3xl px-4 py-2 md:text-sm text-xs"
          >
            View all
          </Link>
        </div>
        <div className="w-full relative">
          <div className="lg:w-full w-11/12 mx-auto">
            <Swiper
              // slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation]}
              navigation={true}
              // spaceBetween={30}
              // pagination={{
              //   clickable: true,
              // }}
              // modules={[Pagination]}
              className="mySwiper home-card"
            >
              {agrochemicalItems}{" "}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="seeds-card lg:my-10">
        <div className="flex justify-between items-center my-2">
          <h2 className="text-lg font-bold ">Seeds</h2>
          <Link
            to="/category/seeds"
            className="hover:underline bg-green-700 text-white  rounded-3xl px-4 py-2 md:text-sm text-xs"
          >
            View all
          </Link>
        </div>
        <div className="w-full relative">
          <div className="lg:w-full w-11/12 mx-auto">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation]}
              navigation={true}
              className="home-card mySwiper"
            >
              {seedItems}{" "}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="foodStuff-card my-4 lg:my-10">
        <div className="flex justify-between items-center my-2">
          <h2 className="text-lg font-bold ">Foodstuffs</h2>
          <Link
            to="/category/food-stuff"
            className="hover:underline bg-green-700 text-white  rounded-3xl px-4 py-2 md:text-sm text-xs"
          >
            View all
          </Link>
        </div>
        <div className="w-full relative">
          <div className="lg:w-full w-11/12 mx-auto">
            <Swiper
              slidesPerView={6}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation]}
              navigation={true}
              className="home-card mySwiper"
            >
              {foodStuffItems}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductCards;
