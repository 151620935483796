import React from "react";
import { Link } from "react-router-dom";

const CheckoutSteps = ({ step1, step2, step3 }) => {
  return (
    <div className="flex justify-around  items-center">
      <div>
        {step1 ? (
          <div className="flex items-center flex-col lg:flex-row gap-1">
            <span class="flex items-center justify-center w-5 h-5 bg-primary-green rounded-full lg:h-6 lg:w-6 dark:bg-primary-orange shrink-0">
              <svg
                class="w-2 h-2 lg:w-3 lg:h-3 text-slate-50"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <Link to="/login" className="text-xs">
              Sign in
            </Link>
          </div>
        ) : (
          <div className="flex items-center flex-col lg:flex-row">
            <span class="flex items-center justify-center w-5 h-5 bg-blue-100 rounded-full lg:h-6 lg:w-6 dark:bg-slate-400 shrink-0">
              <svg
                class="w-2 h-2 text-blue-200 lg:w-3 lg:h-3 dark:text-blue-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <button className="disabled text-xs text-slate-300">Sign in</button>
          </div>
        )}
      </div>
      <div>
        {step2 ? (
          <div className="flex flex-col lg:flex-row items-center gap-1">
            <span class="flex items-center justify-center w-5 h-5 bg-primary-green rounded-full lg:h-6 lg:w-6 dark:bg-primary-orange shrink-0">
              <svg
                class="w-2 h-2 lg:w-3 lg:h-3 text-slate-50"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <Link to="/shipping" className="text-xs">
              Shipping
            </Link>
          </div>
        ) : (
          <div className="flex items-center flex-col lg:flex-row">
            <span class="flex items-center justify-center w-5 h-5 bg-blue-100 rounded-full lg:h-6 lg:w-6 dark:bg-slate-400 shrink-0">
              <svg
                class="w-2 h-2 text-blue-200 lg:w-3 lg:h-3 dark:text-blue-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <button className="disabled text-slate-300">Shipping</button>
          </div>
        )}
      </div>
      {/* <div>
        {step3 ? (
          <div className="flex items-center flex-col lg:flex-row gap-1">
            <span class="flex items-center justify-center w-5 h-5 bg-primary-green rounded-full lg:h-6 lg:w-6 dark:bg-primary-orange shrink-0">
              <svg
                class="w-2 h-2 lg:w-3 lg:h-3 text-slate-50"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <Link to="/payments" className="text-xs">
              Payments
            </Link>
          </div>
        ) : (
          <div className="flex items-center flex-col lg:flex-row gap-1">
            <span class="flex items-center justify-center w-5 h-5 bg-blue-100 rounded-full lg:h-6 lg:w-6 dark:bg-slate-400 shrink-0">
              <svg
                class="w-2 h-2 text-blue-200 lg:w-3 lg:h-3 dark:text-blue-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <button className="disabled text-xs text-slate-300">
              Payments
            </button>
          </div>
        )}
      </div> */}
      <div>
        {step3 ? (
          <div className="flex items-center flex-col lg:flex-row gap-1">
            <span class="flex items-center justify-center w-5 h-5 bg-primary-green rounded-full lg:h-6 lg:w-6 dark:bg-primary-orange shrink-0">
              <svg
                class="w-2 h-2 lg:w-3 lg:h-3 text-slate-50"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <Link to="/placeorder" className="text-xs">
              Place Order
            </Link>
          </div>
        ) : (
          <div className="flex items-center flex-col lg:flex-row gap-1">
            <span class="flex items-center justify-center w-5 h-5 bg-blue-100 rounded-full lg:h-6 lg:w-6 dark:bg-slate-400 shrink-0">
              <svg
                class="w-2 h-2 text-blue-200 lg:w-3 lg:h-3 dark:text-blue-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
            <button className="disabled text-xs text-slate-300">
              Place Order
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSteps;
