import { createSlice } from "@reduxjs/toolkit";

// const formatMoney = (num) =>
//   num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : { cartItems: [], shippingAddress: {} };

const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};
const addDecimal = (num) => {
  return (Math.round(num * 100) / 100)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const updateCart = (state) => {
  state.itemsPrice = addDecimal(
    state.cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)
  );
  localStorage.setItem("cart", JSON.stringify(state));
  return state;
};
// const updateCart = (state) => {
//   state.itemsPrice = addDecimals(
//     state.cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)
//   );
//   localStorage.setItem("cart", JSON.stringify(state));
//   return state;
// };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      if (item <= 0) {
        return item;
      }
      const existItem = state.cartItems.find((x) => x.slug === item.slug);
      if (existItem) {
        state.cartItems = state.cartItems.map((x) =>
          x.slug === existItem.slug ? item : x
        );
      } else {
        state.cartItems = [...state.cartItems, item];
      }

      return updateCart(state);
    },

    // increaseCartItems: (state, action) => {
    //   const itemId = action.payload;
    //   const itemexist = state.cartItems.find((x) => x.slug === itemId.slug);
    //   if (itemexist) {
    //     state.cartItems = state.cartItems.find((item) =>
    //       item.slug === itemId
    //         ? {
    //             ...item,
    //             qty: (item.qty += 1),
    //             slug: item.slug,
    //           }
    //         : item
    //     );
    //   }
    //   localStorage.setItem("cart", JSON.stringify(state));

    //   return updateCart(state);
    // },

    increaseCartItems: (state, action) => {
      const itemId = action.payload;
      state.cartItems = state.cartItems.map((item) =>
        item.slug === itemId
          ? {
              ...item,
              qty: item.qty + 1,
              // slug: item.slug,
            }
          : item
      );
      localStorage.setItem("cart", JSON.stringify(state));

      return updateCart(state);
    },

    // increaseCartItems: (state, action) => {
    //   const itemId = action.payload;
    //   const existItemId = state.cartItems.find((x) => x.slug === itemId.slug);
    //   if (existItemId) {
    //     state.cartItems = state.cartItems.map((x) =>
    //       x.slug === existItemId.slug ? { ...x, qty: (x.qty = x.qty + 1) } : x
    //     );
    //   }

    //   localStorage.setItem("cart", JSON.stringify(state));

    //   return updateCart(state);
    // },

    // increaseCartItems: (state, action) => {
    //   state.cartItems = state.cartItems.map((item) => {
    //     if (item._id === action.payload) {
    //       item.count++;
    //     }
    //     return item;
    //   });
    // },
    // decreaseCartItems: (state, action) => {
    //   state.cartItems = state.cartItems.map((item) => {
    //     if ((item._id === action.payload.id) & (item.count > 1)) {
    //       item.count--;
    //     }
    //     return item;
    //   });
    // },

    decreaseCartItems: (state, action) => {
      state.cartItems = state.cartItems.map((item) =>
        item.slug === action.payload && item.qty > 0
          ? { ...item, qty: item.qty - 1 }
          : item
      );

      localStorage.setItem("cart", JSON.stringify(state));
      return updateCart(state);
    },

    removeCartItems: (state, action) => {
      let decreaseItem = action.payload;
      state.cartItems = state.cartItems.filter(
        (item) => item.slug !== decreaseItem
      );
      state.itemsPrice = addDecimals(
        state.cartItems.reduce((acc, item) => acc + item.price, 0)
      );
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },

    addShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));

      return state;
    },
    addPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));

      return state;
    },

    clearCartItems: (state, action) => {
      state.cartItems = [];
      state.itemsPrice = "";
      localStorage.setItem("cart", JSON.stringify(state));

      return state;
    },
  },
});

export const {
  addToCart,
  increaseCartItems,
  decreaseCartItems,
  removeCartItems,
  addShippingAddress,
  addPaymentMethod,
  clearCartItems,
} = cartSlice.actions;
export default cartSlice.reducer;
