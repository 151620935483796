import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  items: [], // Array to store recently viewed item IDs
};

const recentlyViewedSlice = createSlice({
  name: "recentlyViewed",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { itemId } = action.payload;
      state.items.unshift(itemId); // Add item to the beginning of the list
      // Limit the number of items in the list (optional)
      const maxItems = 5; // Set maximum number of recently viewed items
      state.items = state.items.slice(0, maxItems); // Keep only the first 'maxItems' items
      // Store the updated recently viewed list in the cookie
      Cookies.set("recentlyViewed", JSON.stringify(state.items), {
        expires: 7,
      }); // Expires in 7 days
    },
  },
});

export const { addItem } = recentlyViewedSlice.actions;

export default recentlyViewedSlice.reducer;
