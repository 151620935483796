import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apiSlice";
import globalSliceReducer from "./slices/globalSlice";
import authSliceReducer from "./slices/authSlice";
import paginationSliceReducer from "./slices/paginationSlice";
import cartSliceReducer from "./slices/cartSlice";
import recentlyViewedReducer from "./slices/recentlyViewedSlice";

const store = configureStore({
  reducer: {
    // Add the generated RTK Query "API slice" caching reducer
    [apiSlice.reducerPath]: apiSlice.reducer,
    global: globalSliceReducer,
    cart: cartSliceReducer,
    auth: authSliceReducer,
    pagination: paginationSliceReducer,
    recentlyViewed: recentlyViewedReducer,

    // Add any other reducers
    // users: usersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export default store;
