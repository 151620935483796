import React from "react";
import FeaturedProducts from "../components/ProductSection/FeaturedProducts";
import CategoryNav from "../components/ProductSection/CategoryNav";
import ProductCards from "../components/Global/ProductCards";
import MetaData from "../components/MetaData";

const Home = () => {
  return (
    <section className="w-11/12 mx-auto">
      <MetaData />
      <div className="flex flex-col lg:flex-row justify-between gap-5">
        <div className="basis-2/12 hidden lg:block ">
          <CategoryNav />
        </div>

        <div className="lg:basis-10/12 basis-full mt-20 lg:mt-0">
          <FeaturedProducts />
        </div>
      </div>
      <ProductCards />
    </section>
  );
};

export default Home;
