import { apiSlice } from "./apiSlice";
import {
  ORDERS_URL,
  PRODUCTS_URL,
  MY_ORDERS_URL,
  PAYSTACK_URL,
  LOCATION_URL,
} from "../constants";

const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query(body) {
        return {
          url: `${ORDERS_URL}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Order"],
    }),
    payOrder: builder.mutation({
      query: ({ Id, details }) => ({
        url: `${ORDERS_URL}/${Id}/pay`,
        method: "PUT",
        body: details,
      }),
    }),
    payStackUrl: builder.mutation({
      query(body) {
        return {
          url: `${PAYSTACK_URL}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Order"],
    }),

    getOrders: builder.query({
      query: (page = 1) => ({
        url: `${ORDERS_URL}?page=${page}`,
      }),
      providesTags: ["Order"],
      keepUnusedDataFor: 5,
    }),


 getLocation: builder.query({
      query: () => ({
        url: `${LOCATION_URL}`,
      }),
      providesTags: ["Order"],
      keepUnusedDataFor: 5,
    }),

    getMyOrders: builder.query({
      query: (page = 1) => ({
        url: `${MY_ORDERS_URL}?page=${page}`,
      }),
      providesTags: ["Order"],
      keepUnusedDataFor: 5,
    }),

    getOrderDetails: builder.query({
      query: (Id) => ({ url: `${ORDERS_URL}/${Id}` }),
      keepUnusedDataFor: 5,
    }),
    updateProduct: builder.mutation({
      query: ({ id, updatedProduct }) => ({
        url: `${PRODUCTS_URL}/${id}`,
        method: "PUT",
        body: updatedProduct,
      }),
      invalidatesTags: ["Product"],
    }),
    deliverOrder: builder.mutation({
      query: (Id) => ({
        url: `${ORDERS_URL}/${Id}/deliver`,
        method: "PUT",
      }),
      invalidatesTags: ["Order"],
    }),
    deleteProduct: builder.mutation({
      query: (Id) => ({ url: `${PRODUCTS_URL}/${Id}`, method: "DELETE" }),
    }),
    computeOrder: builder.query({
      query: () => ({
        url: `${ORDERS_URL}/compute`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useComputeOrderQuery,
  useCreateOrderMutation,
  useGetOrdersQuery,
  useGetLocationQuery,
  useDeliverOrderMutation,
  useGetOrderDetailsQuery,
  useGetMyOrdersQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  usePayStackUrlMutation,
  usePayOrderMutation,
} = orderApiSlice;
