import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full bg-black py-10 text-white mt-14">
      <div className="w-11/12 mx-auto">
        <div className="grid grid-cols-2 lg:grid-cols-6 gap-x-6 my-4 grid-flow-row-dense">
          <div className="col-span-4 lg:col-start-1 lg:col-span-2  my-4">
            <h1 className="font-bold text-xl ">
              {" "}
              <img
                src="../images/BsomAgro-white-yellow.png"
                alt="bsom-logo"
                className="w-[150px]"
              />{" "}
            </h1>
            <div className="text-xs">
              <p className="my-1">
                BSOMAGRO is an online e-commerce platform for agricultural
                produce, products and inputs.
              </p>
              <p className="my-2">
                You can shop for your quality agricultural produce, products and
                inputs from us at an affordable rate and it will be delivered to
                you anywhere in Nigeria in a timely fashion.{" "}
              </p>{" "}
              <p className="my-2">
                Can`t find enough quantity of product listed already on our
                platform? We can handle bulk supply of any agricultural product
                to anywhere in Nigeria. You can contact our customer service to
                make arrangemets for bulk purchase.{" "}
              </p>{" "}
              <p className="font-bold text-base">Address:</p>
              <p className="my-2">
                Our office is located at Laniba, Akinyele Local Government,
                Ibadan Oyo state Nigeria.
              </p>
            </div>
          </div>
          <div className="col-span-1 lg:col-start-4">
            <h6 className="text-sm font-bold my-4"> Get To Know Us</h6>
            <Link to="/" className="block text-xs my-4">
              Home
            </Link>
            <Link to="/" className="block text-xs my-4">
              About Us
            </Link>
            <Link to="/" className="block text-xs my-4">
              Contact Us
            </Link>
          </div>

          <div className="my-4 col-span-1 lg:col-start-5">
            <h6 className="text-sm font-bold"> Services</h6>
            <Link to="/" className="block text-xs my-4">
              Consultancy
            </Link>
            <Link to="/" className="block text-xs my-4">
              Investment
            </Link>
            <Link to="/" className="block text-xs my-4">
              Training
            </Link>
          </div>
          <div className="col-span-4 lg:col-start-6 lg:col-span-1 my-4">
            <h6 className="text-sm font-bold">Relevant Links</h6>
            <Link to="/" className="block text-xs my-4">
              Blog
            </Link>
            <Link to="/" className="block text-xs my-4">
              Careers
            </Link>
            <Link to="/" className="block text-xs my-4">
              Privacy
            </Link>
            <Link to="/" className="block text-xs my-4">
              Terms and Conditions
            </Link>
            <Link to="/" className="block text-xs my-4">
              Order Return Policy
            </Link>
          </div>
        </div>
        <p className="text-center text-xs">
          Copyright &copy; {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
