import { apiSlice } from "./apiSlice";
import { CATEGORY_URL } from "../constants";

const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLivestockCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/livestoc`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getLivestockCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/livestock/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getAgrochemicalCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/agrochemical`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getAgrochemicalsCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/agrochemicals/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getSeedlingsCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/seedling`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getSeedlingsCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/seedlings/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getChicksCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/chicks-cat`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getChicksCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/day-old-chicks/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getVetEquipCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/vetrinary-equip`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getVetEquipCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/vetrinary-equipments/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFrozenFoodCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/frozens`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFrozenFoodsCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/frozen-foods/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFoodStuffCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/foods`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFoodStuffCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/foodstuff/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getSeedCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/seed`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getSeedsCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/seeds/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFarmEquipCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/farm-equip`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFarmEquipCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/farm-equipments/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getVegieCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/vegies`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getVegetablesCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/vegetables/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getRootHerbsCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/root-and-herbs`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getRootsHerbsCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/roots-and-herbs/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFertilizerCat: builder.query({
      query: () => ({
        url: `${CATEGORY_URL}/fertilizer`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    getFertilizersCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}/fertilizers/?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    createCategory: builder.mutation({
      query(body) {
        return {
          url: `${CATEGORY_URL}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Category"],
    }),
    getCategories: builder.query({
      query: (page = 1) => ({
        url: `${CATEGORY_URL}?page=${page}`,
      }),
      providesTags: ["Category"],
      keepUnusedDataFor: 5,
    }),

    updateCategory: builder.mutation({
      query: ({ id, updatedCategory }) => ({
        url: `${CATEGORY_URL}/${id}`,
        method: "PUT",
        body: updatedCategory,
      }),
      invalidatesTags: ["Category"],
    }),

    deleteCategory: builder.mutation({
      query: (Id) => ({ url: `${CATEGORY_URL}/${Id}`, method: "DELETE" }),
    }),
  }),
});

export const {
  useGetLivestockCatQuery,
  useGetLivestockCategoriesQuery,
  useGetSeedCatQuery,
  useGetSeedsCategoriesQuery,
  useGetSeedlingsCatQuery,
  useGetSeedlingsCategoriesQuery,
  useGetCategoriesQuery,
  useGetAgrochemicalCatQuery,
  useGetAgrochemicalsCategoriesQuery,
  useGetFoodStuffCatQuery,
  useGetFoodStuffCategoriesQuery,
  useGetFertilizerCatQuery,
  useGetFertilizersCategoriesQuery,
  useGetChicksCatQuery,
  useGetChicksCategoriesQuery,
  useGetFarmEquipCatQuery,
  useGetFarmEquipCategoriesQuery,
  useGetVegieCatQuery,
  useGetVegetablesCategoriesQuery,
  useGetFrozenFoodCatQuery,
  useGetFrozenFoodsCategoriesQuery,
  useGetRootHerbsCatQuery,
  useGetRootsHerbsCategoriesQuery,
  useGetVetEquipCatQuery,
  useGetVetEquipCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApiSlice;
