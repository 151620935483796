import React from "react";
import { useGetFarmEquipCatQuery } from "../../slices/categoryApiSlice";
import { useGetFarmEquipCategoriesQuery } from "../../slices/categoryApiSlice";
import { Link } from "react-router-dom";
import CategoryEmptyScreen from "../../components/CategorySection/CategoryEmptyScreen";
import ScrollToTop from "../../components/Global/ScrollToTop";

const FarmEquipCategory = () => {
  const { data: farmEquipCat } = useGetFarmEquipCatQuery();
  const { data } = useGetFarmEquipCategoriesQuery();
  const formatMoney = (num) =>
    "₦" + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (
    <section className="w-11/12 mx-auto my-10 lg:my-0">
      <div className="pt-10 lg:pt-2">
        {farmEquipCat?.map((cat) => (
          <>
            <img
              src={cat.photo}
              alt=""
              className="w-full h-[200px] lg:h-[250px] object-cover rounded-lg"
            />
          </>
        ))}
      </div>
      <h1 className="text-xl font-bold my-2 text-slate-700">Farm Equipments</h1>
      {data?.products?.length > 0 ? (
        <div className="grid md:grid-cols-4 lg:grid-cols-6 grid-cols-2 gap-2 lg:gap-6 my-3">
          {data?.products?.map((product) => (
            <div className="bg-white rounded-lg" key={product.slug}>
              <Link to={`/products/${product.slug}`} className="w-full my-2">
                <img
                  src={product.photos[0].img}
                  alt=""
                  className="product-image-card"
                />

                <div className="mx-1 text-center text-sm">
                  <h1>{product.title.substring(0, 25)}...</h1>
                  <p className="font-bold p-1">{formatMoney(product.price)}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <CategoryEmptyScreen />
      )}
      <ScrollToTop />
    </section>
  );
};

export default FarmEquipCategory;
