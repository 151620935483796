import React, { useEffect } from 'react';

import CardContainer from '../components/Global/CardContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { IoMdSad } from 'react-icons/io';
import { clearCartItems } from '../slices/cartSlice';
import Loader from '../components/Loader/Loader';

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);

  let formattedPrice = cart.itemsPrice.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [createOrder, { isLoading, error }] = useCreateOrderMutation();

  const formatMoney = (num) => '₦' + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const placeOrderHandler = async () => {
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        itemsPrice: cart.itemsPrice,
      }).unwrap();
      console.log(res);
      console.log(res.order.itemsPrice);
      dispatch(clearCartItems());
      // await new Promise((resolve) => {
      // setTimeout(async () => {
      navigate(`/client/order/${res.order._id}`);
      // resolve();
      // }, 5000);
      // });
      // navigate(`/client/order/${res.order._id}`);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!cart.shippingAddress.address) {
      navigate('/shipping');
    } else {
      navigate('/placeorder');
    }
  }, [navigate, cart.shippingAddress.address]);

  return (
    <CardContainer>
      <div className="relative overflow-x-auto lg:overflow-x-hidden rounded-t-lg shadow-b-0 dark:bg-slate-700 bg-white py-5 text-slate-700 dark:text-slate-300">
        <div className="w-11/12 lg:w-9/12 mx-auto my-5">
          <div className="my-5">
            <CheckoutSteps step1 step2 step3 step4 />
          </div>

          <div className="flex justify-between flex-col lg:flex-row gap-2 ">
            <div className="basis-3/4 border rounded p-3">
              <h2 className="font-bold text-2xl text-slate-700 dark:text-slate-100">Shipping</h2>
              <div className="flex justify-between ">
                <p className="font-bold my-2 whitespace-nowrap text-slate-700 dark:text-slate-100">Address :</p>{' '}
                {/* <div className="w-full"> */}
                <p className="my-2">
                  {cart.shippingAddress.address}, {cart.shippingAddress.lga}, Local government,{' '}
                  {cart.shippingAddress.userstate} State.{' '}
                </p>
                {/* </div> */}
                {/* <div>
                  <p className="font-bold my-2 whitespace-nowrap text-slate-700 dark:text-slate-100">Phonenumber :</p>{' '}
                </div> */}
              </div>

              <p className="font-bold my-2 text-2xl text-slate-700 dark:text-slate-100">Order Items</p>
              {cart.cartItems.length === 0 ? (
                <div className="flex h-screen">
                  <div className="flex flex-1 items-center justify-center text-center">
                    <div>
                      <IoMdSad size={50} className="inline" />
                      <div className="uppercase my-5">
                        <p className="my-4 ">Your Shopping Cart is empty</p>

                        <Link to="/" className="hover:text-primary-green-prelite hover:font-bold">
                          GO SHOPPING
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                cart.cartItems.map((item, index) => (
                  <Link
                    to={`/products/${item.slug}`}
                    key={index}
                    className="flex items-center space-y-3 my-2 gap-2 rounded border"
                  >
                    <div>
                      <img src={item.photos[0].img} alt="" className="h-[50px] w-[50px] rounded-lg" />

                      <div></div>
                    </div>
                    <div>
                      <Link to={`/products/${item.slug}`}>{item.title}</Link>
                      <p>
                        {item.qty} x {formatMoney(item.price)} = {formatMoney(item.qty * item.price)}
                      </p>
                    </div>
                  </Link>
                ))
              )}
            </div>
            <div className="basis-1/2 p-3">
              <h2 className="font-bold text-2xl text-slate-700 dark:text-slate-100">Order Summary</h2>
              {/* <p className="font-bold my-1">Totall Price</p>₦{cart.itemsPrice} */}
              <p className="font-bold my-1 text-slate-700 dark:text-slate-100">Total Price</p>₦{formattedPrice}
              <div>{error && <div>{error}</div>}</div>
              <button
                className="text-center my-1 lg:my-2 dark:bg-primary-orange dark:hover:bg-primary-orange-hover bg-primary-green p-3 rounded block text-slate-50 font-bold"
                onClick={placeOrderHandler}
                disabled={cart.cartItems.length === 0}
              >
                {' '}
                Place Order <span className="inline-block">{isLoading && <Loader className="" />}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};

export default PlaceOrderScreen;
