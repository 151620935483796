import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { FaRegEnvelope } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { useLogoutMutation } from "../../slices/userApiSlice";
import { logout } from "../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

const HomeCard = ({ openHomeCard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApi] = useLogoutMutation();

  const { userInfo } = useSelector((state) => state.auth);
  const logoutHandler = async () => {
    try {
      await logoutApi();

      dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="relative transition ease-in-out duration-300 z-40">
      <div className="bg-white dark:bg-slate-700 h-68 w-[98vw] md:w-60 absolute top-[5.3rem] md:top-16 -right-8 md:right-6 rounded shadow">
        <div className="p-5">
          <div className="block">
            {userInfo.user.role === "admin" ? (
              <div>
                <Link
                  to="/dashboard"
                  className="flex items-center my-2  text-slate-800 hover:text-white hover:bg-primary-green dark:hover:bg-primary-orange dark:text-white rounded"
                >
                  <span className="py-3 mx-3">
                    <FaRegEnvelope />{" "}
                  </span>
                  <span>My Account</span>
                </Link>

                <Link
                  to="/dashboard/orders"
                  className="flex items-center my-2  text-slate-800 hover:text-white hover:bg-primary-green dark:hover:bg-primary-orange dark:text-white rounded"
                >
                  <span className="py-4 mx-3">
                    <FaRegEnvelope />{" "}
                  </span>
                  <span>Orders</span>
                </Link>
              </div>
            ) : (
              <div>
                <Link
                  to="/client"
                  className="flex items-center my-2  text-slate-800 hover:text-white hover:bg-primary-green dark:hover:bg-primary-orange dark:text-white rounded"
                >
                  <span className="py-4 mx-3">
                    <FaRegEnvelope />{" "}
                  </span>
                  <span>My Account</span>
                </Link>
                <Link
                  to="/client/orders"
                  className="flex items-center my-2  text-slate-800 hover:text-white hover:bg-primary-green dark:hover:bg-primary-orange dark:text-white rounded"
                >
                  <span className="py-4 mx-3">
                    <FaRegEnvelope />{" "}
                  </span>
                  <span>Orders</span>
                </Link>
              </div>
            )}

            <div
              className="flex items-center text-slate-800 hover:text-white hover:bg-danger-primary-hover dark:text-white rounded cursor-pointer "
              onClick={logoutHandler}
            >
              <span className="py-4 mx-3">
                <FiLogOut />
              </span>
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
