import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleCart } from "../../slices/globalSlice";
import CartCard from "./CartCard";
import HomeCard from "./HomeCard";
import { BsBasket } from "react-icons/bs";
import CategoryNav from "../ProductSection/CategoryNav";
import SearchBar from "./SearchBar";

const NavBar = () => {
  // const ref = useRef(null);
  const dispatch = useDispatch();
  const cartCard = useSelector((state) => state.global.cartCard);
  const { userInfo } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const [homecardOpen, setHomeCardOpen] = useState(false);

  const [isopen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(!isopen);
  };

  const openHomeCard = () => {
    setHomeCardOpen(!homecardOpen);
  };

  // const submitHandler = () => {};
  return (
    <>
      <header class="fixed lg:relative z-50 w-full bg-primary-green lg:h-24 h-28">
        <div className="w-full text-slate-50 py-3 lg:py-4">
          <nav className="flex items-center justify-between w-11/12 mx-auto">
            <div className="flex items-center gap-1">
              <div className="lg:hidden block">
                <div className="flex items-center space-x-2" onClick={openNav}>
                  <div className={isopen ? "hamburger" : ""}>
                    <span className="mobile-nav-span"></span>
                    <span className="mobile-nav-span"></span>
                    <span className="mobile-nav-span"></span>
                  </div>
                  <div className="block lg:hidden z-20 h-100vh]">
                    {isopen && <CategoryNav />}
                  </div>
                </div>
              </div>
              <Link to="/">
                <img
                  src="../images/BSOMAgro.png"
                  className="md:w-full w-[150px] mx-auto"
                />
                {/* <img
                  src="../images/BsomAgro-white-yellow.png"
                  className="w-[150px] mx-auto md:w-full"
                />{" "} */}
              </Link>
            </div>

            {/* search bar desktop */}
            <div className="relative lg:w-5/12 mx-auto hidden lg:block">
              <SearchBar />
            </div>

            <div className="flex items-center gap-2 md:gap-4">
              <Link to="/login" className="lg:block hidden">
                <p>Blogs</p>
              </Link>
              <div>
                <>{cartCard ? <CartCard /> : ""}</>
                <div
                  className="flex items-center space-x-1 cursor-pointer"
                  onClick={() => dispatch(toggleCart({}))}
                >
                  <BsBasket className="cartIcon" />
                  <p className="md:block hidden">Cart</p>
                  <div
                    className={`absolute inline-flex items-center justify-center w-4 h-4 top-[16px] text-xs text-primary-green bg-white dark:bg-primary-orange border-1 border-white rounded-full dark:border-gray-900 ${
                      cartItems.length > 0 ? "block" : "hidden"
                    }`}
                  >
                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                  </div>
                </div>
              </div>

              <span className="flex items-center">
                {userInfo ? (
                  <>
                    {userInfo.user.photo ? (
                      <span
                        className="bg-green-700 dark:bg-primary-orange border-solid border-4 hover:border-dash-bg w-7 h-7 lg:h-10 lg:w-10 rounded-full cursor-pointer"
                        onClick={openHomeCard}
                      >
                        <img
                          className="flex-1  flex item-center justify-center rounded-full"
                          src={userInfo.user.photo}
                        />
                      </span>
                    ) : (
                      <span
                        className="flex items-center flex-1 justify-center bg-green-700 text-white dark:bg-primary-orange border-solid border-4 hover:border-dash-bg w-7 h-7 lg:h-10 lg:w-10 rounded-full cursor-pointer"
                        onClick={openHomeCard}
                      >
                        <p className="font-bold text-xs capitalize ">
                          {userInfo.user.username.charAt(0)}
                        </p>
                      </span>
                    )}
                    {homecardOpen && (
                      <HomeCard
                        setHomeCardOpen={setHomeCardOpen}
                        openHomeCard={openHomeCard}
                      />
                    )}
                    <span
                      className="mx-1 cursor-pointer"
                      onClick={openHomeCard}
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </span>
                  </>
                ) : (
                  <span className="hidden lg:flex space-x-3">
                    <Link to="/login">
                      <p>Login</p>
                    </Link>
                    <Link to="/register">
                      <p>Sign up</p>
                    </Link>
                  </span>
                )}
              </span>
            </div>
          </nav>
        </div>
        {/* mobile search */}
        <div className="relative lg:w-5/12 mx-auto lg:hidden w-11/12">
          <SearchBar />
        </div>

        {/* <div className="relative lg:w-5/12 mx-auto lg:hidden w-11/12">
          <form onSubmit={submitHandler}>

            <input onChange={(e) =>setKeyword(e.target.value)}
            className="block w-full p-2 lg:p-3 rounded-lg border border-green-700 focus:outline-none focus:ring-green-700 text-slate-600 text-left"
            placeholder="Search productsx..."
          />
          <button
            type="submit"
            className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-green-700 rounded-e-lg border border-green-700 hover:bg-green-700 focus:outline-none focus:ring-green-700"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span class="sr-only">Search</span>
          </button>
          </form>
          
        </div> */}
      </header>
    </>
  );
};

export default NavBar;
