import React from "react";

const Footer = () => {
  return (
    <>
      <p className="text-xs text-center text-slate-700 dark:text-zinc-50 py-3">
        BSOM AGRO v.1.0 &copy; {new Date().getFullYear()}
      </p>
    </>
  );
};

export default Footer;
