import { apiSlice } from "./apiSlice";
import {
  FEATURED_PRODUCTS_URL,
  PRODUCTS_URL,
  RELATED_PRODUCTS_URL,
} from "../constants";

// const productApiSlice = apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     createProduct: builder.mutation({
//       query: ({ body }) => {
//         return {
//           url: `${PRODUCTS_URL}`,
//           method: "POST",
//           body,
//         };
//       },
//       // invalidatesTags: ["User"],
//     }),

const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProduct: builder.mutation({
      query(body) {
        return {
          url: `${PRODUCTS_URL}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Product"],
    }),
    // createProduct: builder.mutation({
    //   query: ({
    //     data,
    //     // formData,
    //     // photos,
    //   }) => {
    //     // const formData = new FormData();
    //     // formData.append("title", title);
    //     // formData.append("description", description);
    //     // formData.append("price", price);
    //     // formData.append("isFeatured", isFeatured);
    //     // formData.append("qty", qty);
    //     // formData.append("category", category);
    //     // photos.forEach((photo, index) => {
    //     //   formData.append(`photo${index}`, photo);
    //     // });

    //     return {
    //       url: PRODUCTS_URL,
    //       method: "POST",
    //       data,
    //       //formData,
    //     };
    //   },
    // }),

    // getProducts: builder.query({
    //   query: ({ keyword, page }) => ({
    //     url: `${PRODUCTS_URL}?keyword=${keyword}&page=${page}`,
    //   }),
    //   providesTags: ["Product"],
    //   keepUnusedDataFor: 5,
    // }),

    getProducts: builder.query({
      query: ({ keyword, page = 1 }) => ({
        url: PRODUCTS_URL,
        params: {
          keyword,
          page,
        },
      }),
      providesTags: ["Product"],
      keepUnusedDataFor: 5,
    }),

    getFeaturedProducts: builder.query({
      query: () => ({
        url: `${FEATURED_PRODUCTS_URL}`,
      }),
      providesTags: ["Product"],
      keepUnusedDataFor: 5,
    }),
    getProductDetails: builder.query({
      query: (slug) => ({ url: `${PRODUCTS_URL}/${slug}` }),
      keepUnusedDataFor: 5,
      // providesTags: ["Product"],
    }),

    // getProductDetails: builder.query({
    //   query: (Id) => ({ url: `${PRODUCTS_URL}/${Id}` }),
    //   keepUnusedDataFor: 5,
    //   // providesTags: ["Product"],
    // }),
    getRelatedProducts: builder.query({
      query: (Id) => ({ url: `${RELATED_PRODUCTS_URL}/${Id}` }),
      keepUnusedDataFor: 5,
      // providesTags: ["Product"],
    }),
    updateProduct: builder.mutation({
      query: ({ id, formData }) => ({
        url: `${PRODUCTS_URL}/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Product"],
    }),
    setFeaturedProduct: builder.mutation({
      query: (Id) => ({
        url: `${PRODUCTS_URL}/featured/${Id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Product"],
    }),
    setUnFeaturedProduct: builder.mutation({
      query: (Id) => ({
        url: `${PRODUCTS_URL}/unfeatured/${Id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Product"],
    }),
    deleteProduct: builder.mutation({
      query: (Id) => ({ url: `${PRODUCTS_URL}/${Id}`, method: "DELETE" }),
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetProductsQuery,
  useGetFeaturedProductsQuery,
  useGetRelatedProductsQuery,
  useGetProductDetailsQuery,
  useUpdateProductMutation,
  useSetFeaturedProductMutation,
  useSetUnFeaturedProductMutation,
  useDeleteProductMutation,
} = productApiSlice;
