import React, { useState, useEffect } from "react";
import CheckoutSteps from "../components/CheckoutSteps";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardContainer from "../components/Global/CardContainer";
import { addPaymentMethod } from "../slices/cartSlice";

const PaymentScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState("PayStack");
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  useEffect(() => {
    if (!shippingAddress) {
      navigate("/shipping");
    }
  }, [navigate, shippingAddress]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(addPaymentMethod(paymentMethod));
    navigate("/placeorder");
  };

  return (
    <CardContainer>
      <div className="relative overflow-x-auto lg:overflow-x-hidden rounded-t-lg shadow-b-0 dark:bg-slate-700 bg-white py-5">
        <div className="w-11/12 lg:w-6/12 mx-auto my-5">
          <div className="my-5">
            <CheckoutSteps step1 step2 step3 />
          </div>
          <h2 className="font-bold text-2xl text-slate-700 dark:text-slate-200">
            Payment Information
          </h2>
          <form onSubmit={submitHandler}>
            <p className="my-2 font-bold">Select Method</p>

            <div class="flex items-center mb-4">
              <input
                id="radio"
                type="radio"
                value={paymentMethod}
                name="radio"
                checked
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="w-4 h-4 accent-primary-green dark:accent-primary-orange "
              />
              <label
                for="radio"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                PayStack
              </label>
            </div>

            <div class="flex items-center mb-4">
              <input
                id="radio"
                type="radio"
                value={paymentMethod}
                name="radio"
                disabled
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="w-4 h-4 accent-primary-green dark:accent-primary-orange "
              />
              <label
                for="radio"
                class="ms-2 text-sm font-medium text-gray-500 dark:text-gray-300"
              >
                PayPal (coming soon)
              </label>
            </div>

            <button className="dark:bg-primary-orange bg-primary-green text-slate-50 p-3 rounded-lg font-bold">
              Continue
            </button>
          </form>
        </div>
      </div>
    </CardContainer>
  );
};

export default PaymentScreen;
