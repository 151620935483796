import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <section className="flex h-screen">
      <div className="flex flex-1 justify-center text-center items-center">
        <div>
          <h6 className="text-lg my-4 font-bold text-slate-700">
            Sorry page was not found
          </h6>
          <p className="my-2 text-slate-700">
            Whoops! It looks like this page got lost.
          </p>
          <div className="my-6">
            <Link
              to="/"
              className="bg-primary-green hover:bg-green-700 p-4 rounded text-white"
            >
              Back To Homepage
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
