import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { useGetOrderDetailsQuery, useDeliverOrderMutation, usePayStackUrlMutation } from '../slices/ordersApiSlice';

import CardContainer from '../components/Global/CardContainer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';

const OrderScreen = () => {
  const dateFormat = (date) => {
    return moment(date).utcOffset(0).format('  MMMM DD YYYY (hh:mm:ss a)');
  };

  const formatMoney = (num) => '₦' + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const { id: Id } = useParams();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  const [payStackUrl] = usePayStackUrlMutation();

  const { isLoading, data: order, error, refetch } = useGetOrderDetailsQuery(Id);

  const handlePaystackPay = async () => {
    let data = {
      amount: order.order.formattedItemsPrice,
      email: userInfo.user.email,
      cart_id: order.order._id,
      phone: order.order.shippingAddress.phonenumber,
      first_name: order.order.shippingAddress.firstname,
      last_name: order.order.shippingAddress.lastname,
    };
    // console.log(data);
    try {
      // Send a POST request to your server to create a Paystack checkout session
      const response = await payStackUrl(data);
      console.log(response);
      // console.log(order);

      // );
      const { authorization_url } = response.data.authorization_url;
      // console.log(authorization_url);
      // Open Paystack payment page in a new tab
      // const paymentWindow = authorization_url;
      // console.log(paymentWindow);
      const paymentWindow = window.open(authorization_url);

      if (paymentWindow) {
        const interval = setInterval(() => {
          if (paymentWindow.closed) {
            // window.location.href = "/success";
            clearInterval(interval);
          }
        }, 1000);
      } else {
        console.error('Failed to open payment window.');
      }
    } catch (error) {
      console.error('Error initializing payment:', error);
      // Handle the error, e.g., show a user-friendly error message to the user.
    } finally {
      // setLoading(false);
    }
  };

  const [deliverOrder, { isLoading: loadingDeliver }] = useDeliverOrderMutation();

  if (!order) {
    return <Loader />;
  }

  const deliverHandler = async () => {
    await deliverOrder(Id);
    toast.success('Item Delivered successfully');
    refetch();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <CardContainer>
          <div className="relative overflow-x-auto lg:overflow-x-hidden rounded-t-lg shadow-b-0 dark:bg-slate-700 bg-white py-5">
            <div className="w-11/12 lg:w-8/12 mx-auto my-5">
              <div className="flex md:flex-row flex-col justify-between gap-2 ">
                <div className=" basis-3/4 border-none md:border-r md:border-dotted md:pr-3">
                  <h3 className="font-bold text-lg">Shipping Information</h3>
                  <div className="flex gap-1 my-3">
                    <p className="font-bold">Name:</p>
                    <p>{order.order.user.username}</p>
                  </div>
                  <div className="flex gap-1 my-3">
                    <p className="font-bold">Email:</p>
                    <p>{order.order.user.email}</p>
                  </div>
                  <div className="flex gap-1 my-3">
                    <p className="font-bold"> Address:</p>
                    <p>
                      {order.order.shippingAddress.address}, {order.order.shippingAddress.lga}, Local government,
                      {order.order.shippingAddress.userstate} State.
                    </p>
                  </div>

                  <div className="flex gap-1 my-3">
                    <p className="font-bold">Phone Number:</p>
                    <p>{order.order.shippingAddress.phonenumber}</p>
                  </div>
                  {''}

                  {order.order.isPaid ? <p>Paid on: {dateFormat(order.order.paidAt)}</p> : <p>Not Paid</p>}

                  {order.order.isDelivered ? (
                    <p className="my-2 p-2 rounded bg-primary-green dark:bg-primary-orange text-white">
                      Delivered on : {dateFormat(order.order.deliveredAt)}
                    </p>
                  ) : (
                    <p className="my-2 p-2 rounded bg-red-300 text-white">Not Delivered</p>
                  )}

                  <h3 className="font-bold text-lg my-2">Order Items</h3>
                  {order?.order?.orderItems.map((item, index) => (
                    <Link
                      to={`/products/${item.product}`}
                      className="flex items-center border border-slate-400 rounded gap-2 my-1 p-1 hover:bg-dash-bg hover:dark:bg-slate-600 dark:hover:border-none text-sm"
                    >
                      <img src={item.photos[0].img} alt={item.name} className="block h-10 w-10 rounded" />
                      <Link to={`/products/${item.product}`} className="">
                        {item.title}
                      </Link>
                      <p className="">
                        {item.qty} x {formatMoney(item.price)} ={' '}
                        <span className="font-bold"> {formatMoney(item.qty * item.price)}</span>
                      </p>
                    </Link>
                  ))}
                </div>

                <div className="text-sm">
                  <h6 className="font-bold text-lg">Order Summary</h6>
                  <div>
                    <div>{formatMoney(order.order.formattedItemsPrice)}</div>
                    {!order.order.isPaid && (
                      <button
                        className="w-full mt-3 bg-primary-green hover:bg-green-700 text-sm text-white p-3 rounded text-center dark:bg-primary-orange dark:hover:bg-primary-orange-hover"
                        onClick={handlePaystackPay}
                      >
                        Pay Now
                      </button>
                    )}
                  </div>
                  {userInfo && userInfo.user.role === 'admin' && order.order.isPaid && !order.order.isDelivered && (
                    <button
                      className="p-3 text-slate-50 rounded my-2 bg-primary-green hover:bg-green-700 dark:bg-primary-orange dark:hover:bg-primary-orange-hover"
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardContainer>
      )}
    </>
  );
};

export default OrderScreen;
