import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "../src/App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import store from "./store";
import "../src/index.css";
import BlogScreen from "./screens/Blog/BlogScreen";
import BlogDetailsScreen from "./screens/Blog/BlogDetailsScreen";
import Dashboard from "./screens/AdminScreen/Dashboard";
import SuccessPage from "./screens/SuccessPage";
import CategoryList from "./screens/AdminScreen/CategoryList";
import ClientDashboard from "./screens/ClientScreen/ClientDashboard";

import Users from "./screens/AdminScreen/UserList";
import BlogList from "./screens/AdminScreen/BlogList";
import ErrorPage from "./components/Errors/ErrorPage";
import DashErrorPage from "./components/Errors/DashErrorPage";
import Login from "./screens/Login";
import Profile from "./screens/Profile";
import OrderScreen from "./screens/OrderScreen";
import ProductList from "./screens/AdminScreen/ProductList";
import FeaturedList from "./screens/AdminScreen/FeaturedList";
import ClientOrdersScreen from "./screens/ClientScreen/ClientOrdersScreen";
import OrderList from "./screens/AdminScreen/OrderList";
import Orders from "./screens/AdminScreen/Orders";
import AccountSetting from "./screens/AccountSetting";

import Register from "./screens/Register";
import AdminRoute from "./components/Layouts/AdminRoute";
import VerifyEmail from "./screens/VerifyEmail";
import ClientDashLayout from "./components/Layouts/ClientDashLayout";
import Home from "./screens/Home";
import NavBar from "./components/Global/NavBar";
import ProductDetails from "./screens/ProductDetails";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import SeedsCategory from "./screens/CategoryScreen/SeedsCategory";
import AgrochemicalsCategory from "./screens/CategoryScreen/AgrochemicalsCategory";
import LivestockCategory from "./screens/CategoryScreen/LivestockCategory";
import SeedlingCategory from "./screens/CategoryScreen/SeedlingCategory";
import ChickCategory from "./screens/CategoryScreen/ChickCategory";
import VetEquipCategory from "./screens/CategoryScreen/VetEquipCategory";
import FrozenCategory from "./screens/CategoryScreen/FrozenCategory";
import FoodStuffCategory from "./screens/CategoryScreen/FoodStuffCategory";
import FarmEquipCategory from "./screens/CategoryScreen/FarmEquipCategory";
import VegetablesCategory from "./screens/CategoryScreen/VegetablesCategory";
import RootHerbsCategory from "./screens/CategoryScreen/RootHerbsCategory";
import Products from "./screens/Products";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />} errorElement={<ErrorPage />}>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/search/:keyword" element={<Products />} />
        <Route path="/search/:keyword/page/:pageNumber" element={<Home />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        <Route path="/blogs" element={<BlogScreen />} />
        <Route path="/blogs/:id" element={<BlogDetailsScreen />} />
        <Route path="/category/seeds" element={<SeedsCategory />} />
        <Route
          path="/category/agrochemicals"
          element={<AgrochemicalsCategory />}
        />
        <Route path="/category/livestock" element={<LivestockCategory />} />
        <Route path="/category/seedlings" element={<SeedlingCategory />} />
        <Route path="/category/day-old-chick" element={<ChickCategory />} />
        <Route
          path="/category/vetrinary-equipments"
          element={<VetEquipCategory />}
        />
        <Route path="/category/frozen-foods" element={<FrozenCategory />} />
        <Route path="/category/food-stuff" element={<FoodStuffCategory />} />
        <Route
          path="/category/farm-equipments"
          element={<FarmEquipCategory />}
        />
        <Route path="/category/vegetables" element={<VegetablesCategory />} />
        <Route
          path="/category/roots-and-herbs"
          element={<RootHerbsCategory />}
        />
      </Route>
      
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verify" element={<VerifyEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/resetpassword/:resettoken" element={<ResetPassword />} />

      <Route element={<AdminRoute />} errorElement={<DashErrorPage />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/bloglist" element={<BlogList />} />
        <Route path="/dashboard/users" element={<Users />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/products" element={<ProductList />} />
        <Route path="/dashboard/featured" element={<FeaturedList />} />
        <Route path="/dashboard/orders" element={<OrderList />} />
        <Route path="/dashboard/orders/id" element={<OrderScreen />} />
        <Route path="/dashboard/categories" element={<CategoryList />} />
        <Route path="/dashboard/edit-account" element={<AccountSetting />} />
        <Route path="/dashboard/orders/:id" element={<OrderScreen />} />
        <Route path="/dashboard/success" element={<SuccessPage />} />
      </Route>

      <Route element={<ClientDashLayout />} errorElement={<ErrorPage />}>
        <Route path="/client" element={<ClientDashboard />} />
        <Route path="/client/profile" element={<Profile />} />
        <Route path="/client/order/:id" element={<OrderScreen />} />
        <Route path="/client/edit-account" element={<AccountSetting />} />
        <Route path="/shipping" element={<ShippingScreen />} />
        <Route path="/payments" element={<PaymentScreen />} />
        <Route path="/placeorder" element={<PlaceOrderScreen />} />
        <Route path="/client/orders" element={<ClientOrdersScreen />} />
        <Route path="/client/success" element={<SuccessPage />} />
      </Route>
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer autoClose={4000} />
    </Provider>
  </HelmetProvider>
);
