import { useState } from "react";
import { TbMoneybag } from "react-icons/tb";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi2";
import CardContainer from "../../components/Global/CardContainer";
import { Link } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  useGetOrdersQuery,
  useComputeOrderQuery,
} from "../../slices/ordersApiSlice";
import { useGetUsersQuery } from "../../slices/userApiSlice";
import Loader from "../../components/Loader/Loader";
import Message from "../../components/Errors/Message";
// import BlogEdit from "../../components/BlogSection/BlogEdit";
// import DialogBox from "../../components/Global/DialogBox";
// import { toast } from "react-toastify";
import Paginate from "../../components/Paginate";
import moment from "moment";

const Dashboard = () => {
  const [page, setPage] = useState(1);
  const { isLoading, data, error, pages, pageSize } =
    // const { isLoading, refetch, data, error, pages, pageSize } =
    useGetOrdersQuery(page);
  const { data: agg } = useComputeOrderQuery();

  const { data: users } = useGetUsersQuery(page);
  // const [open, setOpen] = useState(false);
  const dateFormat = (date) => {
    return moment(date).utcOffset(0).format("  MMMM DD YYYY (hh:mm:ss a)");
  };

  const formatMoney = (num) =>
    "₦" + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <>
      <CardContainer>
        <div className="flex justify-between gap-4 text-center items-center overflow-x-scroll lg:overflow-x-hidden">
          <div className="h-52 px-12 lg:px-0 lg:h-auto py-10 my-2 rounded bg-white transition ease-in-out hover:bg-[#fdfdfd] dark:bg-slate-700 w-full ">
            <div className="flex flex-1 justify-center items-center space-y-2">
              <div className="flex justify-center items-center  rounded-full dark:bg-slate-600 shadow dark:hover:shadow-md dark:hover:bg-slate-500 h-12 w-12 transition ease-in-out">
                <TbMoneybag size={20} />
              </div>
            </div>

            <div className="my-1">
              <p>Total Sales</p>
              <h1 className="font-bold text-2xl">
                {" "}
                {/* {formatMoney(paidTrueData.total)} */}
              </h1>
            </div>
          </div>

          <div className="h-52 px-12 lg:px-0 lg:h-auto py-10 rounded bg-white transition ease-in-out hover:bg-[#fdfdfd] dark:bg-slate-700  w-full">
            <div className="flex flex-1 justify-center items-center space-y-2">
              <div className="flex justify-center items-center rounded-full dark:bg-slate-600 shadow dark:hover:shadow-md dark:hover:bg-slate-500 h-12 w-12 transition ease-in-out">
                <MdOutlineStackedLineChart size={20} />
              </div>
            </div>
            <div className="my-1 w-full">
              <p>Order Completed</p>
              <h1 className="font-bold text-2xl">{data?.count}</h1>
            </div>
          </div>

          <div className="h-52 px-12 lg:px-0 lg:h-auto py-10 rounded bg-white transition ease-in-out hover:bg-[#fdfdfd] dark:bg-slate-700  w-full">
            <div className="flex flex-1 justify-center items-center space-y-2">
              <div className="flex justify-center items-center  rounded-full dark:bg-slate-600 shadow dark:hover:shadow-md dark:hover:bg-slate-500 h-12 w-12 transition ease-in-out">
                <HiOutlineUsers size={20} />
              </div>
            </div>
            <div className="my-1">
              <p>Customers</p>
              <h1 className="font-bold text-2xl">{users?.count}</h1>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col space-x-0 md:space-x-10 justify-between">
          <div className="my-5 h-96 w-full md:w-6/12 bg-white dark:bg-slate-700">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={730}
                height={250}
                data={agg}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="ItemProduct" stackId="a" fill="#8884d8" />
                <Bar dataKey="ItemQty" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="my-5 h-96 w-full md:w-6/12 bg-white dark:bg-slate-700">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={730}
                height={250}
                data={agg}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="ItemProduct" stackId="a" fill="#8884d8" />
                <Bar dataKey="ItemQty" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="flex text-slate-800 dark:text-slate-50 justify-between items-center gap-3 lg:flex-row flex-col mx-auto">
          <div className="w-full lg:flex-1 lg:my-10 my-5 p-4 lg:p-8  rounded shadow bg-white dark:bg-slate-700">
            <h1 className="font-semibold mb-3">Recent Transaction</h1>
            <div className=" ">
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message>{error}</Message>
              ) : (
                <>
                  <div className="relative overflow-x-auto  bg-white">
                    <table className="table-auto w-full bg-[#fdfdfd] dark:bg-slate-600 svg:dark">
                      <thead className="text-left text-sm text-slate-600 dark:text-white border-b">
                        <tr className="whitespace-nowrap">
                          <th scope="col" className=" px-3 py-3">
                            #
                          </th>
                          <th scope="col" className=" px-3 py-3">
                            ID
                          </th>
                          <th scope="col" className=" px-3 py-3">
                            USER
                          </th>

                          <th scope="col" className=" px-3 py-3">
                            PAID
                          </th>

                          <th scope="col" className=" px-3 py-3 overflow-x">
                            DATE
                          </th>
                          <th scope="col" className=" px-3 py-3">
                            TOTAL
                          </th>
                          <th scope="col" className=" px-3 py-3">
                            REFERENCE
                          </th>
                          <th scope="col" className=" px-3 py-3">
                            DELIVERED
                          </th>

                          <th scope="col" className=" px-3 py-3">
                            DETAILS
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.orders.map((order, index) => (
                          <tr className="border-b px-3 py-3" key={order._id}>
                            <td className="border-b px-3 py-3">
                              {(data.page - 1) * data.pageSize + index + 1}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              {order._id}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-pre lg:whitespace-normal ">
                              {order.user.username}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              {order.isPaid ? (
                                <span className="rounded bg-primary-green p-2 dark:bg-primary-orange text-slate-50 text-xs">
                                  Paid
                                </span>
                              ) : (
                                <span className="rounded bg-red-400 p-2 text-slate-50 text-xs">
                                  Not Paid
                                </span>
                              )}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              {dateFormat(order.createdAt)}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              {formatMoney(order.formattedItemsPrice)}{" "}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              {order.reference ? (
                                <span>{order.reference}</span>
                              ) : (
                                "null"
                              )}{" "}
                            </td>
                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              {order.isDelivered ? (
                                <span className="rounded bg-primary-green p-2 dark:bg-primary-orange text-slate-50 text-xs">
                                  Delivered
                                </span>
                              ) : (
                                "Not Delivered"
                              )}
                            </td>

                            <td className="border-b px-3 py-3 whitespace-nowrap">
                              <Link
                                to={`/dashboard/orders/${order._id}`}
                                className="rounded bg-primary-green p-2 dark:bg-primary-orange dark:hover:bg-primary-orange-hover hover:bg-green-700 text-slate-50 text-xs"
                              >
                                Details
                              </Link>
                            </td>

                            {/* <td className="border-b px-3 py-3">
                      <span className="flex justify-start space-x-4 align-items-center">
                        <BlogEdit className="mx-10" />

                        <AiOutlineDelete onClick={() => setOpen(true)} />

                        {open && (
                          <DialogBox
                            open={open}
                            setOpen={setOpen}
                            // handleDelete={() => handleDelete(post._id)}
                          />
                        )}
                      </span>
                    </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Paginate
                    pages={pages}
                    pageSize={pageSize}
                    page={page}
                    data={data}
                    setPage={setPage}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/* <div className="w-full lg:w-72 px-8 py-8 rounded shadow bg-white dark:bg-slate-700">
          <h1 className="font-semibold">Adverts</h1>
          <div className="my-10 py-16 rounded bg-white dark:bg-slate-700 ">
            lsjkdkdlzkdklkdkkl
          </div>
        </div> */}
      </CardContainer>
    </>
  );
};

export default Dashboard;
