import React, { useState } from "react";
import { BiInjection } from "react-icons/bi";
import { FaBottleDroplet, FaCow, FaLeaf, FaSeedling } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { GiChicken, GiHerbsBundle, GiPlantSeed } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoryNav = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [cardOpen, setCardOpen] = useState(true);
  const handleClick = () => {
    setCardOpen(!cardOpen);
  };
  return (
    <aside
      className={`${
        cardOpen ? "cardOpen" : "cardClose"
      } bg-white z-10 min-h-full h-full lg:h-auto w-full rounded-none lg:rounded-lg lg:relative lg:top-0 fixed top-[2.9rem] right-0 left-0 mt-0 lg:mt-1`}
      onClick={handleClick}
    >
      <ul className="m-0 p-0 cat-nav text-slate-700 ">
        <div className="block lg:hidden">
          <div className="mx-4 my-4">
            {!userInfo && (
              <div className="flex justify-between gap-3">
                <NavLink
                  to="/login"
                  className="text-center basis-1/2 border border-primary-green p-2"
                >
                  Login
                </NavLink>
                <NavLink
                  to="/register"
                  className="text-center basis-1/2 border border-primary-green p-2"
                >
                  Register
                </NavLink>
              </div>
            )}
          </div>

          <hr />
          <p className="text-base font-semibold mx-5 my-3">Categories</p>
        </div>
        <NavLink to="/category/livestock">
          <div className="flex items-center space-x-2">
            <FaCow />
            <p>Livestock</p>
          </div>
        </NavLink>
        <NavLink to="/category/agrochemicals" className="cursor-pointer">
          <div className="flex items-center space-x-2">
            <FaBottleDroplet />
            <p>Agrochemicals</p>
          </div>
        </NavLink>
        <NavLink to="/category/seedlings">
          <div className="flex items-center space-x-2">
            <FaSeedling />
            <p> Seedlings</p>
          </div>
        </NavLink>
        <NavLink to="/category/day-old-chick">
          <div className="flex items-center space-x-2">
            <GiChicken />
            <p>Day Old Chicks</p>
          </div>
        </NavLink>
        <NavLink to="/category/vetrinary-equipments">
          <div className="flex items-center space-x-2">
            <BiInjection />
            <p>Vetrinary Equipments</p>
          </div>
        </NavLink>
        <NavLink to="/category/frozen-foods">
          <div className="flex items-center space-x-2">
            <FaBottleDroplet />
            <p>Frozen Foods</p>
          </div>
        </NavLink>
        <NavLink to="/category/food-stuff">
          <div className="flex items-center space-x-2">
            <PiBowlFoodFill />
            <p>Food Stuff</p>
          </div>
        </NavLink>
        <NavLink to="/category/seeds">
          <div className="flex items-center space-x-2">
            <GiPlantSeed />
            <p>Seeds</p>
          </div>
        </NavLink>
        <NavLink to="/category/farm-equipments">
          <div className="flex items-center space-x-2">
            <GiPlantSeed />
            <p>Farm Equipments</p>
          </div>
        </NavLink>
        <NavLink to="/category/vegetables">
          <div className="flex items-center space-x-2">
            <FaLeaf />
            <p>Vegetables</p>
          </div>
        </NavLink>
        <NavLink to="/category/roots-and-herbs">
          <div className="flex items-center space-x-2">
            <GiHerbsBundle />
            <p>Roots and Herbs</p>
          </div>
        </NavLink>
      </ul>
    </aside>
  );
};

export default CategoryNav;
