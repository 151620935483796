import React from "react";
import { IoMdSad } from "react-icons/io";
import { Link } from "react-router-dom";

const CategoryEmptyScreen = () => {
  return (
    <div className="flex h-1/2">
      <div className="flex flex-1 items-center justify-center text-center">
        <div>
          <IoMdSad size={50} className="inline my-5 text-slate-700" />
          <p className="text-slate-700">No product found in this category </p>

          <Link
            to="/"
            className="block text-center my-5 bg-primary-green hover:bg-green-700 text-slate-50 p-3 rounded-lg"
          >
            Go Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CategoryEmptyScreen;
