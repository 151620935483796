import React from "react";

const SuccessPage = () => {
  return (
    <section className="text-center w-full">
      <div className="w-1/2 mx-auto">
        <p>Payment Successful</p>
        <p className="p-3 bg-primary-green text-white text-center">Back Home</p>
      </div>
    </section>
  );
};

export default SuccessPage;
