import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addShippingAddress } from '../slices/cartSlice';
import { useGetLocationQuery } from '../slices/ordersApiSlice';
import CardContainer from '../components/Global/CardContainer';
import CheckoutSteps from '../components/CheckoutSteps';

const ShippingScreen = () => {
  const { data: locations } = useGetLocationQuery();
  console.log(locations);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [firstname, setFirstname] = useState(shippingAddress?.firstname || '');
  const [lastname, setLastname] = useState(shippingAddress?.lastname || '');
  const [phonenumber, setPhonenumber] = useState(shippingAddress?.phonenumber || '');
  const [address, setAddress] = useState(shippingAddress?.address || '');
  const [userstate, setuserState] = useState(shippingAddress?.userstate || '');
  const [subItems, setSubItems] = useState([]);
  const [lga, setLga] = useState(shippingAddress?.lga || '');

  const handleState = (e) => {
    const selectedTitle = e.target.value;
    setuserState(selectedTitle);

    const selectedItem = locations.find((item) => item.title === selectedTitle);
    setSubItems(selectedItem ? selectedItem.sub : []);
  };

  const handleLgaChange = (e) => {
    const selectedTitle = e.target.value;
    setLga(selectedTitle);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addShippingAddress({ firstname, lastname, phonenumber, address, userstate, lga }));
    console.log({ addShippingAddressHere: addShippingAddress });
    navigate('/placeorder');
  };

  return (
    <CardContainer>
      <div className="relative overflow-x-auto lg:overflow-x-hidden rounded-t-lg shadow-b-0 dark:bg-slate-700 bg-white py-5">
        <div className="w-11/12 lg:w-6/12 mx-auto my-5">
          <CheckoutSteps step1 step2 />
          <h2 className="p-3 md:mx-3 font-bold text-2xl text-slate-700 dark:text-slate-200">Shipping Information</h2>
          <form className="md:mx-3 text-slate-600" onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="mx-3 mb-4">
                <label className="block mb-1 font-semibold text-sm text-slate-500 dark:text-slate-200">
                  First Name
                </label>
                <input
                  className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder="Enter First Name"
                  required
                />
              </div>

              <div className="mx-3 mb-4">
                <label className="block mb-1 font-semibold text-sm text-slate-500 dark:text-slate-200">Last Name</label>
                <input
                  className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="Enter Lastname"
                  required
                />
              </div>
            </div>

            <div className="mx-3 mb-4">
              <label className="block mb-1 font-semibold text-sm text-slate-500 dark:text-slate-200">
                Phone Number
              </label>
              <input
                className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                type="text"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
                placeholder="Enter Phonenumber"
                required
              />
            </div>

            <div className="mx-3 mb-4">
              <label className="block mb-1 font-semibold text-sm text-slate-500 dark:text-slate-200">
                Delivery Address
              </label>
              <input
                className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Address"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row justify-between ">
              {/* <div className="mx-3 mb-4 ">
              <label className="block mb-1 text-sm font-semibold text-slate-500 dark:text-slate-200">
               State
              </label>
              <input
                className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter City"
                required
              />
            </div> */}

              <div className="mx-3 mb-4">
                <label className="block mb-1 text-sm font-semibold text-slate-500 dark:text-slate-200">State</label>
                <select
                  id="role"
                  className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                  onChange={handleState}
                  value={userstate}
                >
                  <option selected>Select State</option>
                  {locations?.map((item) => (
                    <option value={item.title} key={item.id}>
                      {item.title.replace(/^./, (str) => str.toUpperCase())}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mx-3 mb-4 ">
                <label className="block mb-1 text-sm font-semibold text-slate-500 dark:text-slate-200">
                  Local Government
                </label>

                <select
                  onChange={handleLgaChange}
                  value={lga}
                  className="rounded border-0 p-3 w-full border-slate-200 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:primary-green-lite"
                >
                  <option selected>Select Local Government</option>
                  {subItems?.map((sub) => (
                    <option value={sub.title} key={sub.id}>
                      {sub.title.replace(/^./, (str) => str.toUpperCase())}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mx-3 float-right py-6 space-y-6 ">
              <button
                className="mt-4 text-base font-semibold p-3 w-full bg-primary-green dark:bg-primary-orange text-white rounded"
                type="submit"
              >
                <span className="inline">Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </CardContainer>
  );
};

export default ShippingScreen;
