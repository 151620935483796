import React, { useState } from "react";
import CardContainer from "../../components/Global/CardContainer";
import Message from "../../components/Errors/Message";
import { useGetMyOrdersQuery } from "../../slices/ordersApiSlice";
import Loader from "../../components/Loader/Loader";
import Paginate from "../../components/Paginate";
import moment from "moment";
import { Link } from "react-router-dom";

const ClientOrdersScreen = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading, error, pages, pageSize } = useGetMyOrdersQuery();

  const dateFormat = (date) => {
    return moment(date).utcOffset(0).format("  MMMM DD YYYY (hh:mm:ss a)");
  };

  const formatMoney = (num) =>
    "₦" + num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <CardContainer>
      <div className="flex justify-between items-center my-2">
        <h3 className="text-left py-3 md:text-base font-bold text-sm text-slate-700 dark:text-white">
          My Orders
        </h3>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error.message}</Message>
      ) : (
        <>
          <>
            <div className="relative overflow-x-auto lg:overflow-x-hidden rounded-t-lg shadow bg-white">
              <table className="table-auto w-full dark:bg-slate-600 bg-white svg:dark">
                <thead className="text-left text-sm text-slate-600 dark:text-white border-b">
                  <tr className="whitespace-nowrap">
                    <th scope="col" className=" px-3 py-3">
                      #
                    </th>

                    <th scope="col" className=" px-3 py-3 overflow-x">
                      ORDER ID
                    </th>
                    <th scope="col" className=" px-3 py-3">
                      CREATED AT
                    </th>
                    <th scope="col" className=" px-3 py-3">
                      TOTAL PRICE
                    </th>
                    <th scope="col" className=" px-3 py-3">
                      PAID
                    </th>
                    <th scope="col" className=" px-3 py-3">
                      DELIVERED
                    </th>
                    <th scope="col" className=" px-3 py-3">
                      DETAILS
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.orders?.map((order, index) => (
                    <tr className="border-b px-3 py-3" key={order._id}>
                      <td className="border-b px-3 py-3">
                        {(data.page - 1) * data.pageSize + index + 1}
                      </td>

                      <td className="border-b px-3 py-3 whitespace-pre lg:whitespace-normal ">
                        {order._id}
                      </td>
                      <td className="border-b px-3 py-3 whitespace-nowrap">
                        {dateFormat(order.createdAt)}
                      </td>
                      <td className="border-b px-3 py-3 whitespace-nowrap">
                        {formatMoney(order.formattedItemsPrice)}
                      </td>
                      <td className="border-b px-3 py-3 whitespace-nowrap">
                        {order.isPaid ? (
                          <span className="rounded bg-primary-green p-2 dark:bg-primary-orange text-slate-50 text-xs">
                            Paid
                          </span>
                        ) : (
                          <span className="rounded bg-red-400 p-2 text-slate-50 text-xs">
                            Not Paid
                          </span>
                        )}
                      </td>
                      <td className="border-b px-3 py-3 whitespace-pre lg:whitespace-normal ">
                        {order.isDelivered ? (
                          dateFormat(order.deliveredAt)
                        ) : (
                          <p>Not Delivered</p>
                        )}
                      </td>

                      <td className="border-b px-3 py-3 whitespace-pre lg:whitespace-normal ">
                        <Link
                          to={`/client/order/${order._id}`}
                          className="p-2 dark:bg-primary-orange bg-primary-green text-slate-50 rounded text-sm"
                        >
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginate
              pages={pages}
              pageSize={pageSize}
              page={page}
              data={data}
              setPage={setPage}
            />
          </>
        </>
      )}
    </CardContainer>
  );
};

export default ClientOrdersScreen;
